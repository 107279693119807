import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import HubspotForm from 'react-hubspot-form'
import CognitoForm from "../components/cognitoForm"

const ContactUsPage = (props) => {

  const [ heroImage, setHeroImage ] = useState("https://images.prismic.io/levelfivesolutions/3344c6b7-803d-4261-85eb-f040a77b6fb0_banner_contact_hireus.jpg?auto=compress%2Cformat&fit=max&q=50");

  const { data } = props;
      
  console.log('>>>', data);
  let contactUs = data.contact_us.nodes[0].data;
  let social = data.social.nodes[0].data;
  console.log('contact us -->', contactUs);
  console.log('social -->', social);
  
  useEffect( ()=> {
    setHeroImage(contactUs.project_inquiry_wide_image.url);
    console.log("Supposed to be tab: ", getCategoryUidFromUrl());
    setActiveTab(getCategoryUidFromUrl());
  },[])

  if (!contactUs) return null;

  //returns the active tab uid from the URL
  function getCategoryUidFromUrl() {
    return (window.location.search != "") ? window.location.search.match(/(=)\w+/)[0].replace("=","") : "";
  }

  //Sets the url parameters for the active tab
  function setUrlWithUid(uid) {
    var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + '?tab=' + uid;    
    window.history.pushState({ path: refresh }, '', refresh);
  }

  //Clicks a tab to make it active
  function setActiveTab(uid) {
    if(uid) {
      let tabs = document.getElementsByTagName('a');
      console.log("TABS", tabs);
      for (let tab of tabs) {
        if(tab.hash === `#${uid}`) {
          tab.click();
        }
      }
    }
  }


  function handleTabChange(url, uid) {
    setHeroImage(url);
    setUrlWithUid(uid);
  }

  return (
    <Layout>
    <SEO title={ contactUs.meta_title } image={ contactUs.meta_og_image && contactUs.meta_og_image.url } description={ contactUs.meta_description } canonical={ typeof window !== 'undefined' && (window.location.origin + window.location.pathname)}/>
      {/* preload bg images */}
      <div style={{ display: "none"}}>
          <img src={contactUs.project_inquiry_wide_image.url} alt={contactUs.project_inquiry_wide_image.alt}/>
          <img src={contactUs.join_our_team_wide_image.url} alt={contactUs.join_our_team_wide_image.alt}/>
          <img src={contactUs.find_us_wide_image.url} alt={contactUs.find_us_wide_image.alt}/>
      </div>
      <div className="title-bar banner-contact" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="container">
          <div className="wrapper">
            <h1 className="page-title">Contact</h1>
            <div className="clearfix"></div>
            <div className="nav-tabs-wrapper">
              <ul role="tablist" className="nav nav-tabs">
                <li role="presentation" className="active"><a href="#project" onClick={ () => handleTabChange(contactUs.project_inquiry_wide_image.url, "project") }  aria-controls="" role="tab" data-toggle="tab">PROJECT INQUIRY</a></li>
                <li role="presentation"><a href="#join" onClick={ () => handleTabChange(contactUs.join_our_team_wide_image.url, "join") }  aria-controls="" role="tab" data-toggle="tab">JOIN OUR TEAM</a></li>
                <li role="presentation"><a href="#other" onClick={ () => handleTabChange(contactUs.find_us_wide_image.url, "other") }  aria-controls="" role="tab" data-toggle="tab">FIND US</a></li>
              </ul>
            </div>
            <div className="title-bar-accent"></div>
          </div>
        </div>
      </div>

      {/*  PAGE BODY  */}
      <section id="body-area" style={{ background: "none" }}>
        <div className="container">
          <div className="inset">
            <div className="tab-content">
              <div id="project" role="tabpanel" className="tab-pane fade in active">
                <h2 className="headline">{contactUs.project_inquiry_title.text}</h2>
                <div className="row">
                  <div id="project-form" className="col-md-7">
                    <CognitoForm id={42} />
                  </div>
                  <aside className="sidebar col-md-4 col-md-offset-1">
                    <div className="box style-1">
                      <h2 className="tag">{contactUs.project_inquiry_callout_box_label}</h2>
                      <div dangerouslySetInnerHTML={{ __html: contactUs.project_inquiry_callout_box_body_text.html }}></div>
                      <div className="row" style={{ display: "flex", alignItems: "center"}}>
                        <div className="col-xs-3 txt-right"><img src="/images/icn-phone.svg" width="32" alt="Icon of a phone" /></div>
                        <div className="col-xs-9">
                          <a href={`tel:+${social.phone_number.replace(/\+| |\-|\(|\)/g,'')}`}><h3 className="gamma">{ social.phone_number }</h3></a></div>
                      </div>
                    </div> {/*  /box  */}
                  </aside>
                </div>
              </div>

              <div id="join" role="tabpanel" className="tab-pane fade">
                <h2 className="headline">{contactUs.join_our_team_title.text}</h2>
                <div className="row">
                  <div className="col-md-7">
                    <CognitoForm id={41} />
                  </div>
                  <aside className="sidebar col-md-4 col-md-offset-1">
                    <div className="box style-1">
                      <h2 className="tag">{contactUs.join_our_team_callout_box_label}</h2>
                      <div dangerouslySetInnerHTML={{ __html: contactUs.join_our_team_callout_box_body_text.html }}></div>
                    </div> {/*  /box  */}
                  </aside>
                </div>
              </div>

              <div id="other" role="tabpanel" className="tab-pane fade">
                <h2 className="headline">{ contactUs.find_us_title.text }</h2>
                <div className="row">
                  <div className="col-md-6 margin-top-10">
                    <div dangerouslySetInnerHTML={{ __html: social.office_address.html }}></div>
                    <p className={`d-flex align-items-center margin-bottom-0`}>
                      <strong>P</strong>&nbsp;&nbsp;
                      <a href={`tel:+${social.phone_number.replace(/\+| |\-|\(|\)/g,'')}`}>{ social.phone_number}</a>
                    </p> 
                    <p className="d-flex align-items-center margin-bottom-0">
                      <strong>F</strong>&nbsp;&nbsp;
                      <a href={`tel:+${social.fax_number.replace(/\+| |\-|\(|\)/g,'')}`}>{ social.fax_number}</a>
                    </p>
                    <p className="d-flex align-items-center margin-bottom-20">
                      <strong>E</strong>&nbsp;&nbsp;<a href={`mailto:${social.email_address}`}>{ social.email_address }</a>
                    </p>

                    <div className="social-media">
                    {
                          social.social_links.map( (d, i ) => {
                            return(
                              <a key={ i } href={ d.social_link.url } target="_blank">
                                <img width="30" src={ d.social_icon.url } alt={ d.social_icon.alt }/>&nbsp;
                              </a>
                            )
                          })
                        }
                    </div>
                  </div>
                  <aside className="sidebar col-md-6 margin-top-10">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3105.6576045973065!2d-94.67265324889846!3d38.8860748794723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c0c1dc9cd32115%3A0xd392afd3de9949ad!2sLevel+Five+Solutions+Inc.!5e0!3m2!1sen!2sus!4v1453149581187" width="500" height="450" frameBorder="0" style={{ border:0 }} allowFullScreen></iframe>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </Layout>
  );
}

export default ContactUsPage;

export const contactUsQuery = graphql`
query ContactUsQuery {
  contact_us: allPrismicContactUsPage {
    nodes {
      data {
        find_us_title {
          text
        }
        join_our_team_callout_box_body_text {
          text
          html
        }
        join_our_team_callout_box_label
        join_our_team_form_id
        join_our_team_portal_id
        project_inquiry_callout_box_body_text {
          text
          html
        }
        join_our_team_title {
          text
        }
        project_inquiry_callout_box_label
        project_inquiry_form_id
        project_inquiry_portal_id
        project_inquiry_title {
          text
        }
        project_inquiry_wide_image {
          alt
          url
        }
        join_our_team_wide_image {
          alt
          url
        }
        find_us_wide_image {
          alt
          url
        }
        meta_title
        meta_description
        meta_og_image {
          url
        }
      }
    }
  }
  social: allPrismicSocialInformation {
    nodes {
      data {
        email_address
        fax_number
        phone_number
        office_address {
          html
        }
        social_links {
          social_icon {
            alt
            url
          }
          social_link {
            url
          }
          social_name
          social_service
        }
      }
    }
  }
}`