import React from "react"

class CognitoForm extends React.Component {

  componentDidMount() {
    window.Cognito.mount(String(this.props.id), `#cognitoForm${this.props.id}`)
  }

  render() {

    return (
      <div
        className={`cognito cognitoForm`}
        id={`cognitoForm${this.props.id}`}
      >
      </div>
    )
  }
}

export default CognitoForm
